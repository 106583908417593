import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/styles/withStyles';
import { FormHelperText } from '@lc/legacy/src/Components';

import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';

import * as yup from 'yup';

export const passwordValidateSchema = yup
  .string()
  .min(8)
  .required();

const PasswordTextField = ({
  classes,
  className,
  showIcon,
  register,
  error,
  value,
  onChange,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisibility] = useState(false);

  return (
    <>
      <TextField
        fullWidth
        className={clsx(classes.input, className)}
        label={t('password')}
        name="password"
        onChange={onChange}
        value={value}
        type={!passwordVisible ? 'password' : 'text'}
        inputRef={register({ required: true })}
        InputProps={{
          endAdornment: !showIcon ? (
            undefined
          ) : (
            <InputAdornment
              position="end"
              style={{ cursor: 'pointer' }}
              onClick={() => setPasswordVisibility(!passwordVisible)}
            >
              {!passwordVisible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </InputAdornment>
          ),
        }}
        {...otherProps}
      />
      <FormHelperText type="small" error={!!error} message={t('emptyPasswordFieldWarning')} />
    </>
  );
};

const styles = theme => ({
  input: {
    '& input, & label': {
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    '& svg': {
      width: '22px',
      marginRight: theme.spacing(2),
      color: 'hsla(0, 0%, 20%, .5)',
    },
  },
});

PasswordTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  value: PropTypes.string,
};

PasswordTextField.defaultProps = {
  className: undefined,
  error: undefined,
  value: undefined,
  onChange: undefined,
  showIcon: true,
};

export default withStyles(styles)(PasswordTextField);
