const styles = theme => ({
  warningList: {
    padding: theme.spacing(0),
  },
  warningListIcon: {
    minWidth: theme.typography.pxToRem(23),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    color: theme.palette.error.main,
  },
  successListIcon: {
    minWidth: theme.typography.pxToRem(23),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    color: theme.palette.secondary.main,
  },
  warningListText: {
    fontSize: '12px',
    '& .MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(10),
      },
      color: theme.palette.error.main,
    },
  },
  successListText: {
    fontSize: '12px',
    '& .MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(10),
      },
      color: theme.palette.secondary.main,
    },
  },
  weak: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  good: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
});

export default styles;
