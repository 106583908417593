import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, withStyles, Link } from '@material-ui/core';
import CheckIcon from 'components/SignUpForm/PasswordStrengthInfo/CheckIcon';
import { useSnackbar } from 'contexts';
import PasswordTextField from 'components/TextField/PasswordTextField';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@lc/legacy/src/Components';
import Modal from 'components/Modal';
import {
  checkPasswordScore,
  checkPasswordRules,
} from 'components/SignUpForm/PasswordStrengthInfo/PasswordStrengthInfo';
import clsx from 'clsx';
import * as yup from 'yup';
import api from 'api';

const INVALID_ID_STATUS_CODE = 400;
const ValidateSchema = yup.object().shape({
  password: yup.string().required(),
});

const PassHelperText = ({ classes, password, confirmPassword, onlyCheckIfMatch }) => {
  const { t } = useTranslation(['changePassword', 'feedbacks']);
  const matchRules = checkPasswordRules(password);
  const passwordCondition = checkPasswordScore(password) && matchRules;
  const checkCondition = onlyCheckIfMatch ? password === confirmPassword : passwordCondition;
  const rulesHelperMessage = !matchRules
    ? t('confirmResetPasswordGuide')
    : t('feedbacks:passwordStrength', { context: checkCondition ? 'strong' : 'weak' });
  const mustRenderNothing = !password || (onlyCheckIfMatch && !passwordCondition);

  return mustRenderNothing ? null : (
    <div className={classes.container}>
      <CheckIcon
        checkCondition={checkCondition}
        className={checkCondition ? classes.success : classes.error}
      />
      <Typography
        component="p"
        variant="button"
        className={checkCondition ? classes.success : classes.error}
      >
        {onlyCheckIfMatch
          ? t('feedbacks:passwordConfirmed', { context: checkCondition ? 'success' : 'failure' })
          : rulesHelperMessage}
      </Typography>
    </div>
  );
};

const StyledPassHelperText = withStyles(theme => ({
  container: { display: 'flex', flexDirection: 'row', marginTop: theme.spacing(0.8) },
  error: { color: theme.palette.error.icon, minWidth: theme.typography.pxToRem(23) },
  success: { color: theme.palette.success.icon, minWidth: theme.typography.pxToRem(23) },
}))(PassHelperText);

const ConfirmResetPasswordModal = ({ classes, id, onClose }) => {
  const { t } = useTranslation(['changePassword', 'feedbacks', 'forms', 'common']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const [, setMessage] = useSnackbar();
  const { register, handleSubmit, errors } = useForm({ validationSchema: ValidateSchema });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fetching, setFetching] = useState(false);

  const onSubmit = async () => {
    if (
      !checkPasswordRules(password) ||
      !checkPasswordScore(password) ||
      password !== confirmPassword
    ) {
      return;
    }

    setFetching(true);
    const [response, error] = await api.confirmPasswordReset(password, id);
    setFetching(false);

    if (error) {
      if (response.error.status === INVALID_ID_STATUS_CODE) {
        setMessage({ message: t('feedbacks:resetPasswordInvalidToken'), variant: 'error' });
        onClose();
      } else {
        setMessage({ message: t('feedbacks:confirmResetPasswordFailure'), variant: 'error' });
      }
    } else {
      setMessage({ message: t('feedbacks:confirmResetPasswordSuccess'), variant: 'info' });
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} closeButton>
      <Typography variant={xsSize ? 'body1' : 'h6'} component="h6" className={classes.title}>
        <strong>{t('resetPassword')}</strong>
      </Typography>
      <Typography variant={xsSize ? 'body2' : 'body1'} color="textPrimary" align="center">
        {t('confirmResetPasswordGuide')}
      </Typography>
      <PasswordTextField
        value={password}
        error={errors.password}
        register={register}
        onChange={({ target: { value } }) => {
          setPassword(value);
        }}
        className={classes.input}
      />
      <StyledPassHelperText password={password} />
      <PasswordTextField
        value={confirmPassword}
        error={errors.confirmPassword}
        register={register}
        onChange={({ target: { value } }) => {
          setConfirmPassword(value);
        }}
        className={clsx(classes.input, classes.marginTop)}
        name="confirmPassword"
        label={t('forms:confirmPasswordField')}
      />
      <StyledPassHelperText
        password={password}
        confirmPassword={confirmPassword}
        onlyCheckIfMatch
      />
      <div className={classes.buttonMargin}>
        <Button
          loading={fetching}
          fullWidth
          variant="contained"
          size={xsSize ? 'small' : 'medium'}
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {t('common:send')}
        </Button>
      </div>
      <Typography variant="button" className={classes.disclaimer}>
        <Trans i18nKey="confirmResetPasswordDisclaimer" ns="changePassword">
          <Link href="/terms/terms-of-service" className={classes.linkDialog} target="_blank">
            first node
          </Link>
          <Link href="/terms/privacy-policy" className={classes.linkDialog} target="_blank">
            second node
          </Link>
        </Trans>
      </Typography>
    </Modal>
  );
};

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '568px',
    padding: theme.spacing(12, 11, 5, 11),
    '&:focus': {
      outline: 'none !important',
      outlineOffset: 'none !important',
    },
  },
  title: {
    color: '#383838',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(5),
    '& input, & label': {
      fontSize: theme.typography.pxToRem(14),
    },
    '& svg': {
      width: '22px',
      marginRight: theme.spacing(2),
      color: 'hsla(0, 0%, 20%, .5)',
    },
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  fetchingContainer: {
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1.1),
  },
  disclaimer: {
    margin: theme.spacing(0, 1),
  },
});

PassHelperText.propTypes = {
  classes: PropTypes.object.isRequired,
  confirmPassword: PropTypes.string,
  onlyCheckIfMatch: PropTypes.bool,
  password: PropTypes.string,
};
PassHelperText.defaultProps = {
  password: '',
  confirmPassword: '',
  onlyCheckIfMatch: false,
};

ConfirmResetPasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ConfirmResetPasswordModal.defaultProps = {
  id: undefined,
};

export default withStyles(styles)(ConfirmResetPasswordModal);
