import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useUserInfo } from '@lc/lib/Provider';

import { scroll } from 'helpers';
import Modal from 'components/Modal';

import styles from './index.style';

const RegisterExplore = ({ classes, onClose }) => {
  const { isLogged } = useUserInfo();
  const { t } = useTranslation('register');
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <Modal onClose={onClose}>
      <>
        <img
          src="/images/LetClinic_subtitle.png"
          style={{
            width: '146px',
            height: '120px',
            marginBottom: '47px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          alt="Let Clinic"
        />
        <Typography variant={xsSize ? 'body1' : 'h6'} align="center">
          <strong>{t('allRight')}</strong>
        </Typography>
        <Typography
          variant={xsSize ? 'body1' : 'h6'}
          align="center"
          style={{ marginBottom: '6px' }}
        >
          {t('pleasureToHaveYouWithUs')}
        </Typography>
        <Typography
          variant={xsSize ? 'body2' : 'body1'}
          color="textPrimary"
          align="center"
          style={{ marginBottom: '32px' }}
        >
          {t('exploreMessage')}
        </Typography>
        <Button
          onClick={() => {
            if (window.location.pathname.includes('/video') && isLogged) {
              Router.push('/room');
            }
            if (window.location.pathname.includes('/video') && !isLogged) {
              scroll.to('#howVideoWorks');
            }
            onClose();
          }}
          size={xsSize ? 'small' : 'large'}
          variant="contained"
          color="primary"
          className={classes.exploreButton}
          fullWidth
        >
          {t('startExploring')}
        </Button>
      </>
    </Modal>
  );
};

RegisterExplore.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterExplore);
