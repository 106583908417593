const styles = theme => ({
  formContainer: {
    width: '520px',
    padding: theme.spacing(6, 7),
    textAlign: 'center',
  },
  joinTitle: {
    paddingBottom: theme.spacing(2),
  },
  radio: {
    '&$checked': {
      color: '#0031FF',
    },
  },
  signupField: {
    margin: theme.spacing(1, 0),
  },
  exploreButton: {
    margin: theme.spacing(3, 0),
  },
});

export default styles;
