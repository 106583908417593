import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ConfirmResetPasswordModal from 'features/ResetPassword/Components/ConfirmResetPasswordModal';
import EmailValidateToken from 'features/Register/email/emailValidateToken';
import Explore from 'features/Register/explore';

const ValidationOnScreenModal = ({ query }) => {
  const [confirmResetPassVisible, setConfirmResetPassVisibility] = useState(false);
  const [expiredEmailModal, setExpiredEmailModal] = useState(false);
  const [canExploreModal, setCanExploreModal] = useState(false);

  const checkShowModalExplore = () => {
    const canExplore = JSON.parse(sessionStorage.getItem('canExplore'));
    if (canExplore) {
      setCanExploreModal(true);
    }
    sessionStorage.setItem('canExplore', false);
  };

  useEffect(() => {
    setTimeout(() => {
      checkShowModalExplore();
    }, 1000);

    if (query === undefined) return;
    const { t: type } = query;
    if (type === undefined) return;

    setExpiredEmailModal(type === 'email');
    setConfirmResetPassVisibility(type === 'password');
  }, [query]);

  return (
    <>
      {confirmResetPassVisible && (
        <ConfirmResetPasswordModal
          id={query.id}
          onClose={() => setConfirmResetPassVisibility(false)}
        />
      )}
      {expiredEmailModal && (
        <EmailValidateToken token={query.token} onClose={() => setExpiredEmailModal(false)} />
      )}
      {canExploreModal && <Explore onClose={() => setCanExploreModal(false)} />}
    </>
  );
};

ValidationOnScreenModal.propTypes = {
  query: PropTypes.object.isRequired,
};

export default ValidationOnScreenModal;
