import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import ModalMUI from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import { useMediaQuery, useTheme } from '@material-ui/core';

const Modal = ({
  classes,
  Header,
  onClose,
  closeButton,
  children,
  maxWidth,
  maxHeight,
  styleBody,
  styleModal,
  useEverMaxHeight,
  ...rest
}) => {
  const handleClose = () => {
    onClose();
  };
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <ModalMUI open onClose={handleClose} {...rest}>
      <div
        className={classes.paper}
        style={{
          maxWidth,
          maxHeight: !useEverMaxHeight && xsSize ? 'auto' : maxHeight,
          ...styleModal,
        }}
      >
        {closeButton && (
          <button className={classes.closeButton} type="button" onClick={handleClose}>
            <Close className={classes.closeIcon} />
          </button>
        )}
        {Header}
        <div className={classes.body} style={{ ...styleBody }}>
          {children}
        </div>
      </div>
    </ModalMUI>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  closeButton: PropTypes.bool,
  Header: PropTypes.node,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  styleBody: PropTypes.object,
  styleModal: PropTypes.object,
  useEverMaxHeight: PropTypes.bool,
};
Modal.defaultProps = {
  maxWidth: '554px',
  maxHeight: '100vh',
  Header: <></>,
  styleBody: {},
  styleModal: {},
  closeButton: false,
  useEverMaxHeight: false,
};

const styles = theme => ({
  paper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '36px 24px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '0',
    borderRadius: '4px',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      borderRadius: 0,
    },
    '@media screen and ( max-height: 400px )': {
      height: '100vh',
    },
    '&:focus': {
      outline: 'none !important',
      outlineOffset: 'none !important',
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '@media screen and ( max-height: 600px )': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 100px)', // 24*2 padding
      justifyContent: 'flex-start',
    },
  },
  closeButton: {
    border: '0',
    position: 'absolute',
    right: '20px',
    [theme.breakpoints.down('xs')]: {
      top: '50px',
      right: '18px',
    },
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none !important',
      outlineOffset: 'none !important',
    },
  },
  closeIcon: {
    // top: -25,
    // left: -50,
    // position: 'absolute',
    // fontSize: theme.typography.pxToRem(28),
    // color: 'hsla(0, 0%, 0%, 1)',
  },
});
export default withStyles(styles)(Modal);
