/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Typography, withStyles } from '@material-ui/core';
import zxcvbn from 'zxcvbn';
import CheckIcon from './CheckIcon';
import styles from './PasswordStrengthInfo.style';

export const minLength = 7;
export const passRegex = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-z]).*$/m; // match password rules

export const checkPasswordRegex = password => passRegex.test(password);

export const checkPasswordScore = password => {
  const getPassWordScore = zxcvbn(password).score;
  return getPassWordScore > 1;
};

export const checkNumberOrSymbols = password => {
  const hasNumber = /\d/.test(password);
  const hasSpecialCharacters = /\W|_/g.test(password);
  return hasNumber || hasSpecialCharacters;
};

export const checkPasswordRules = password => {
  const checkPassWordSize = password.length > minLength;
  const checkAllRules = [
    checkPasswordScore(password),
    checkPassWordSize,
    checkNumberOrSymbols(password),
    checkPasswordRegex(password),
  ];
  return checkAllRules.every(rules => rules === true);
};

const PasswordStrengthInfo = ({ classes, formProps, setPasswordStatus }) => {
  const { t } = useTranslation('feedbacks');
  const getPasswordValue = formProps.values.password;
  const getPassWordStrengthLabel = checkPasswordRules(getPasswordValue)
    ? t('strongPass')
    : t('weakPass');

  useEffect(() => {
    setPasswordStatus(checkPasswordRules(getPasswordValue));
  }, [getPasswordValue]);

  return (
    <div className={classes.warningContainer}>
      <List>
        <ListItem className={classes.warningList}>
          <CheckIcon
            checkCondition={checkPasswordRules(getPasswordValue)}
            className={
              checkPasswordRules(getPasswordValue)
                ? classes.successListIcon
                : classes.warningListIcon
            }
          />
          <ListItemText
            className={
              checkPasswordRules(getPasswordValue)
                ? classes.successListText
                : classes.warningListText
            }
            primary={
              <Typography component="p" variant="body1">
                {t('passStrength')}
                <b className={checkPasswordRules(getPasswordValue) ? classes.good : classes.weak}>
                  {getPassWordStrengthLabel}
                </b>
              </Typography>
            }
          />
        </ListItem>
        <ListItem className={classes.warningList}>
          <CheckIcon
            checkCondition={getPasswordValue.length > minLength}
            className={
              getPasswordValue.length > minLength
                ? classes.successListIcon
                : classes.warningListIcon
            }
          />
          <ListItemText
            primary={t('passRule8Chars')}
            className={
              checkPasswordRules(getPasswordValue)
                ? classes.successListText
                : classes.warningListText
            }
          />
        </ListItem>
        <ListItem className={classes.warningList}>
          <CheckIcon
            checkCondition={checkNumberOrSymbols(getPasswordValue)}
            className={
              checkNumberOrSymbols(getPasswordValue)
                ? classes.successListIcon
                : classes.warningListIcon
            }
          />
          <ListItemText
            primary={t('passRuleNumberOrSymbol')}
            className={
              checkPasswordRules(getPasswordValue)
                ? classes.successListText
                : classes.warningListText
            }
          />
        </ListItem>
      </List>
    </div>
  );
};

PasswordStrengthInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  formProps: PropTypes.object.isRequired,
  setPasswordStatus: PropTypes.func.isRequired,
};

export default withStyles(styles)(PasswordStrengthInfo);
