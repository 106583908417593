/* eslint-disable jsx-a11y/anchor-is-valid */
// @todo: get user location from api
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from 'api';
import Modal from 'components/Modal';
// import Router from 'next/router'

import { useTranslation } from 'react-i18next';

import { withStyles, Typography, Link, CircularProgress } from '@material-ui/core';

import styles from './index.style';

const EmailValidateToken = ({ classes, token, onClose }) => {
  const [isValidating, setIsValidating] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation(['register', 'feedbacks']);

  const sendAgain = async () => {
    setIsFetching(true);
    await api.resendEmail();
    setIsFetching(false);
  };

  useEffect(() => {
    const fetch = async () => {
      setIsValidating(true);
      const [, error] = await api.activeEmail(token);
      if (error) {
        setIsValidating(false);
      } else {
        onClose();
        sessionStorage.setItem('canExplore', true);
        // Router.replace('/')
      }
    };
    fetch();
  }, []);

  return (
    <Modal onClose={onClose} closeButton>
      {isValidating && <CircularProgress />}
      {!isValidating && (
        <>
          <Typography variant="h6" component="h3" className={classes.title}>
            {t('linkExpired')}
          </Typography>
          <Typography
            paragraph
            variant="body2"
            color="textPrimary"
            className={classes.subtitle}
            dangerouslySetInnerHTML={{
              __html: t('explainExpiredEmail'),
            }}
          />
          {isFetching && <CircularProgress />}
          <div className={classes.content}>
            <div className={classes.links}>
              <Link href="#" onClick={sendAgain}>
                <Typography color="primary" variant="body2">
                  {t('resendEmail')}
                </Typography>
              </Link>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

EmailValidateToken.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default withStyles(styles)(EmailValidateToken);
