import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

const CheckIcon = ({ className, checkCondition }) => (
  <ListItemIcon className={className}>
    {checkCondition ? <Check fontSize="small" /> : <Close fontSize="small" />}
  </ListItemIcon>
);

CheckIcon.propTypes = {
  checkCondition: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

CheckIcon.defaultProps = {
  checkCondition: false,
};

export default CheckIcon;
